.Experience-role-list {
  padding-inline-start: 0;
  list-style-type: none;
}

.Experience-role-list-item {
  margin: 24px 0;
}

.Experience-role-list-item:first-of-type {
  margin-top: 12px;
}

.Experience-role-list-item > .Experience-highlight-list {
  margin-top: 4px;
}

.Experience-highlight-list {
  padding-inline-start: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.Experience-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
