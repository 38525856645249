.Intro h1 {
  margin-bottom: 16px;
}

.Intro-contact {
  display: flex;
  justify-content: space-between;
}

.Intro-contact-link {
  display: flex;
  align-items: center;
  height: 20px;
}

.Intro-logo {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}
