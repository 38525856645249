.Text-subheading {
  color: #282c3499;
  font-size: 13px;
}

.Text-title {
  color: tomato;
  font-size: 13px;
}

.Text-body-light {
  color: #282c3499;
}
