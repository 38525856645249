.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
}

.App-section {
  border-radius: 8px;
  max-width: 816px;
  width: calc(100% - 72px);
  background-color: white;
  padding: 24px 32px;
  margin: 0.25vmin 0;
}

.App-section-heading {
  text-align: center;
}

.App-sub-section > div {
  margin-top: 2vmin;
}

.App-sub-section > div::after {
  content: "";
  display: block;
  border-bottom: 1px solid #61dafb90;
  margin-top: 2vmin;
}

.App-sub-section > div:last-of-type::after {
  border-bottom: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-show-on-print {
  display: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

a,
li,
p {
  font-size: 14px;
}

li {
  margin: 2px 0;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

a,
a:visited {
  color: #282c34;
}

@media print {
  .App {
    padding: 0;
    background-color: white;
  }

  .App a {
    text-decoration: none;
  }

  .App-section {
    margin: 0;
    padding: 12px 0;
  }

  .App-section-heading {
    padding: 12px 0;
    border: 1px solid #282c34;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #282c3411;
    print-color-adjust: exact;
  }

  .App-sub-section > div::after {
    border-bottom-width: 1px;
  }

  .App-show-on-print {
    display: inline-block;
  }

  .App-hide-on-print {
    display: none;
  }
}
