.Glance-heading {
  color: #282c3499;
  font-weight: bold;
  font-size: 14px;
}

.Glance-areas-of-expertise {
  columns: 3;
  margin: 0;
  padding-inline-start: 24px;
}
