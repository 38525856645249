.Project {
  display: flex;
  margin: 2vmin 0;
}

.Project img {
  margin-right: 12px;
  width: 48px;
  height: 48px;
  border-radius: 22.5%;
}
